import { createSignal } from "solid-js";
import "./TestForm.scss";

const TestForm = () => {
  const [email, setEmail] = createSignal("");
  const [firstName, setFirstName] = createSignal("");
  const [lastName, setLastName] = createSignal("");
  const [phoneNumber, setPhoneNumber] = createSignal("");

  const handleSubmit = (e) => {
    e.preventDefault();
    gtag("set", "user_data", {
      email: email(),
      phone_number: phoneNumber(),
      address: {
        first_name: firstName(),
        last_name: lastName(),
        street: "",
        city: "",
        region: "",
        postal_code: "",
        country: "",
      },
    });
    gtag("event", "conversion", {
      send_to: "AW-16651671669/NZ4OCLTxy9EZEPWwkYQ-",
    });
    alert(
      `Submitted!\nEmail: ${email()}\nPhone Number: ${phoneNumber()}\nFirst Name: ${firstName()}\nLast Name: ${lastName()}`
    );
  };

  return (
    <div className="TestForm">
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email()}
            onInput={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>First Name:</label>
          <input
            type="text"
            value={firstName()}
            onInput={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Last Name:</label>
          <input
            type="text"
            value={lastName()}
            onInput={(e) => setLastName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Phone Number:</label>
          <input
            type="tel"
            value={phoneNumber()}
            onInput={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default TestForm;
