import { render } from "solid-js/web";
import { Router, Route, Routes, A, Navigate } from "@solidjs/router";
import TestForm from "./pages/test_form/TestForm.js";
import CustomConversion from "./pages/custom_conversion/CustomConversion.js";
import "./App.scss";

const NavBar = () => (
  <div className="NavBar">
    <A href="/">Home</A>
    <A href="/test_form">Test Form</A>
    <A href="/custom_conversion">Custom Conversion</A>
  </div>
);

const Content = () => {
  return (
    <div className="Content">
      <Routes>
        <Route
          end
          path="/"
          component={() => (
            <span className="Content-text">{"Hello A&M :)"}</span>
          )}
        />
        <Route
          end
          path="/test_form"
          component={() => <TestForm />}
        />
        <Route
          end
          path="/custom_conversion"
          component={() => <CustomConversion />}
        />
      </Routes>
    </div>
  );
};

const App = () => (
  <Router>
    <NavBar />
    <Content />
  </Router>
);

const rootElement = document.getElementById("root");
render(() => <App />, root);
